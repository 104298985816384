import React, { useState } from "react";
import "./ReviewsSlider.styles.scss";

const reviews = [
  {
    name: "Khanyisa Joka",
    rating: 5,
    review:
      "The place is very clean and the staff members are so friendly and kind. They don't keep you waiting. I'd recommend their services to anyone.",
    avatar: "assets/images/khanyisa.webp",
  },
  {
    name: "Lerato Legoabe",
    rating: 5,
    review:
      "Such an amazing place, if you want your printing issues sorted come to Nyalu... very friendly staff. Also big ups to Mr Thabang for the hospitality.",
    avatar: "assets/images/lerato.webp",
  },
  {
    name: "Calvin Themba",
    rating: 5,
    review:
      "The company has grown so much into a communication firm that I admire. Advertising and much more.",
    avatar: "assets/images/calvin.webp",
  },
];

const ReviewsSlider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slidesToShow = 2; // Show 2 slides at a time

  const handlePrevClick = () => {
    setCurrentSlide((prev) =>
      prev === 0 ? reviews.length - slidesToShow : prev - 1
    );
  };

  const handleNextClick = () => {
    setCurrentSlide((prev) =>
      prev === reviews.length - slidesToShow ? 0 : prev + 1
    );
  };

  return (
    <div className="reviews-slider">
      <div className="slider-container">
        <div className="slider-content">
          {reviews.map((review, index) => (
            <div
              key={index}
              className={`review-card ${
                index >= currentSlide && index < currentSlide + slidesToShow
                  ? "active"
                  : ""
              }`}
              style={{
                transform: `translateX(-${currentSlide * 100}%)`,
              }}
            >
              <div className="image-container">
                <img src={review.avatar} alt={review.name} className="avatar" />
                <h4>{review.name}</h4>
              </div>
              <div className="rating">
                <svg
                  width="94"
                  height="16"
                  viewBox="0 0 94 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_82_1586)">
                    <path
                      d="M7.8568 1.08973C8.13179 0.436561 9.0684 0.436562 9.3434 1.08973L10.9664 4.94484C11.0824 5.2202 11.3445 5.40834 11.6454 5.43217L15.8582 5.76584C16.572 5.82237 16.8614 6.70236 16.3176 7.16257L13.1079 9.87879C12.8787 10.0728 12.7785 10.3773 12.8485 10.6673L13.8292 14.7287C13.9953 15.4167 13.2376 15.9607 12.6265 15.5919L9.01972 13.4155C8.7621 13.26 8.4381 13.26 8.18048 13.4155L4.57369 15.5919C3.96259 15.9607 3.20486 15.4167 3.371 14.7287L4.35164 10.6673C4.42168 10.3773 4.32156 10.0728 4.0923 9.87879L0.882547 7.16257C0.338723 6.70236 0.628152 5.82237 1.34193 5.76584L5.55478 5.43217C5.8557 5.40834 6.11782 5.2202 6.23375 4.94484L7.8568 1.08973Z"
                      fill="#DD7426"
                    />
                    <path
                      d="M27.0568 1.08973C27.3317 0.436561 28.2684 0.436562 28.5433 1.08973L30.1664 4.94484C30.2824 5.2202 30.5445 5.40834 30.8454 5.43217L35.0582 5.76584C35.772 5.82237 36.0614 6.70236 35.5176 7.16257L32.3079 9.87879C32.0786 10.0728 31.9784 10.3773 32.0485 10.6673L33.0291 14.7287C33.1952 15.4167 32.4376 15.9607 31.8264 15.5919L28.2197 13.4155C27.962 13.26 27.638 13.26 27.3804 13.4155L23.7736 15.5919C23.1625 15.9607 22.4048 15.4167 22.571 14.7287L23.5516 10.6673C23.6216 10.3773 23.5215 10.0728 23.2923 9.87879L20.0825 7.16257C19.5387 6.70236 19.8281 5.82237 20.5419 5.76584L24.7547 5.43217C25.0556 5.40834 25.3178 5.2202 25.4337 4.94484L27.0568 1.08973Z"
                      fill="#DD7426"
                    />
                    <path
                      d="M46.2567 1.08973C46.5317 0.436561 47.4683 0.436562 47.7433 1.08973L49.3663 4.94484C49.4823 5.2202 49.7444 5.40834 50.0453 5.43217L54.2581 5.76584C54.9719 5.82237 55.2613 6.70236 54.7175 7.16257L51.5078 9.87879C51.2786 10.0728 51.1784 10.3773 51.2484 10.6673L52.2291 14.7287C52.3952 15.4167 51.6375 15.9607 51.0264 15.5919L47.4196 13.4155C47.162 13.26 46.838 13.26 46.5804 13.4155L42.9736 15.5919C42.3625 15.9607 41.6048 15.4167 41.7709 14.7287L42.7515 10.6673C42.8216 10.3773 42.7215 10.0728 42.4922 9.87879L39.2824 7.16257C38.7386 6.70236 39.0281 5.82237 39.7418 5.76584L43.9547 5.43217C44.2556 5.40834 44.5177 5.2202 44.6337 4.94484L46.2567 1.08973Z"
                      fill="#DD7426"
                    />
                    <path
                      d="M65.4564 1.08973C65.7314 0.436561 66.668 0.436562 66.943 1.08973L68.566 4.94484C68.682 5.2202 68.9442 5.40834 69.245 5.43217L73.4578 5.76584C74.1716 5.82237 74.461 6.70236 73.9172 7.16257L70.7075 9.87879C70.4783 10.0728 70.3781 10.3773 70.4482 10.6673L71.4288 14.7287C71.5949 15.4167 70.8372 15.9607 70.2261 15.5919L66.6193 13.4155C66.3617 13.26 66.0377 13.26 65.7801 13.4155L62.1733 15.5919C61.5622 15.9607 60.8045 15.4167 60.9706 14.7287L61.9512 10.6673C62.0213 10.3773 61.9212 10.0728 61.6919 9.87879L58.4822 7.16257C57.9383 6.70236 58.2278 5.82237 58.9415 5.76584L63.1544 5.43217C63.4553 5.40834 63.7174 5.2202 63.8334 4.94484L65.4564 1.08973Z"
                      fill="#DD7426"
                    />
                    <path
                      d="M84.6566 1.08973C84.9316 0.436561 85.8682 0.436562 86.1432 1.08973L87.7662 4.94484C87.8822 5.2202 88.1443 5.40834 88.4452 5.43217L92.658 5.76584C93.3718 5.82237 93.6612 6.70236 93.1174 7.16257L89.9077 9.87879C89.6785 10.0728 89.5783 10.3773 89.6483 10.6673L90.629 14.7287C90.7951 15.4167 90.0374 15.9607 89.4263 15.5919L85.8195 13.4155C85.5619 13.26 85.2379 13.26 84.9803 13.4155L81.3735 15.5919C80.7624 15.9607 80.0047 15.4167 80.1708 14.7287L81.1514 10.6673C81.2215 10.3773 81.1214 10.0728 80.8921 9.87879L77.6824 7.16257C77.1385 6.70236 77.428 5.82237 78.1417 5.76584L82.3546 5.43217C82.6555 5.40834 82.9176 5.2202 83.0336 4.94484L84.6566 1.08973Z"
                      fill="#DD7426"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_82_1586">
                      <rect
                        width="92.7998"
                        height="15.1111"
                        fill="white"
                        transform="translate(0.600098 0.599854)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <p>"{review.review}"</p>
            </div>
          ))}
        </div>
      </div>
      <div className="dots">
        {reviews.slice(0, reviews.length - 1).map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentSlide ? "active" : ""}`}
            onClick={() => setCurrentSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default ReviewsSlider;
