import React, { useEffect } from "react";
import { gsap } from "gsap";
import "./LogoScroller.styles.scss"; // Ensure this file contains the CSS styles

const LogoScroller = () => {
  useEffect(() => {
    const wrap = gsap.utils.wrap(-100, 400);

    // Initially colorize each box and position in a row
    gsap.set(".box", {
      y: (i) => i * 100,
    });

    gsap.to(".box", {
      duration: 25,
      ease: "none",
      y: "-=500", // Move each box up
      modifiers: {
        y: gsap.utils.unitize(wrap), // Force y value to wrap when it reaches -100
      },
      repeat: -1,
    });
  }, []);

  return (
    <div>
      <div className="wrapper">
        <div className="boxes">
          <>
            <div className="box">
              <img src="assets/svg/logos/sanlam-logo.svg" alt="" />
              <img src="assets/svg/logos/old-mutual-logo.svg" alt="" />
              <img src="assets/svg/logos/eskom-logo.svg" alt="" />
              <img src="assets/images/logos/inseta-logo.webp" alt="" />
            </div>
            <div className="box">
              <img src="assets/svg/logos/mtn-logo.svg" alt="" />
              <img src="assets/svg/logos/shell-logo.svg" alt="" />
              <img src="assets/svg/logos/absa-logo.svg" alt="" />
              <img src="assets/svg/logos/prasa-logo.svg" alt="" />
            </div>
            <div className="box">
              <img src="assets/svg/logos/sabc-logo.svg" alt="" />
              <img src="assets/images/logos/cross-border-logo.webp" alt="" />
              <img src="assets/svg/logos/rand-water-logo.svg" alt="" />
              <img src="assets/images/logos/gep-logo.webp" alt="" />
            </div>
            <div className="box">
              <img src="assets/svg/logos/joburg-logo.svg" alt="" />
              <img src="assets/svg/logos/sanpark-logo.svg" alt="" />
              <img src="assets/svg/logos/transnet-logo.svg" alt="" />
              <img src="assets/images/logos/sassa-logo.webp" alt="" />
            </div>
            <div className="box">
              <img src="assets/svg/logos/sanlam-logo.svg" alt="" />
              <img src="assets/svg/logos/old-mutual-logo.svg" alt="" />
              <img src="assets/svg/logos/eskom-logo.svg" alt="" />
              <img src="assets/images/logos/inseta-logo.webp" alt="" />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default LogoScroller;
