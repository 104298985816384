import React from "react";
import "./EmbroideryServices.styles.scss";
import ServiceList from "../ServiceList/ServiceList";
const EmbroideryServices = () => {
  return (
    <>
      <section className="EmbroideryServices">
        <div className="container">
          <section className="EmbroideryServices__custom">
            <div className="EmbroideryServices__custom-left">
              <h2>Custom Embroidery</h2>
            </div>
            <div className="EmbroideryServices__custom-right">
              <ServiceList
                paragraphs={[
                  "High-quality embroidery on various fabrics and garments.",
                  "This service allows for personalized designs and logos to be stitched into clothing and accessories, making it ideal for custom apparel and corporate branding.",
                ]}
                services={["T-shirts", "Jackets", "Caps", "Uniforms"]}
              />
            </div>
            <div className="EmbroideryServices__custom-bottom">
              <img src="assets/images/embroidery-cap.webp" alt="" />
            </div>
          </section>
        </div>
      </section>
      <section className="EmbroideryServices corp">
        <div className="container">
          <section className="EmbroideryServices__corporate">
            <div className="EmbroideryServices__corporate-left">
              <img src="assets/images/corporate-embroidery.webp" alt="" />
            </div>
            <div className="EmbroideryServices__corporate-right">
              <ServiceList
                heading="CMT"
                headingSize="large"
                paragraphs={[
                  "Our Cut, Make, and Trim (CMT) department is dedicated to delivering high-quality garment production tailored to your specific needs. From pattern making and fabric cutting to sewing and finishing, we ensure every detail meets your standards.",
                  "Our skilled artisans use advanced techniques and equipment to produce garments efficiently while maintaining exceptional craftsmanship",
                  "We cater to a range of styles and sizes, making us the ideal partner for fashion brands and retailers looking to bring their visions to life. With a focus on quality control and timely delivery, our CMT department is here to support your production needs from start to finish!",
                ]}
                services={[]}
              />
            </div>
          </section>
        </div>
      </section>
    </>
  );
};

export default EmbroideryServices;
