import React, { useState, useEffect } from "react";
import "./Hero.styles.scss";
import { Link } from "react-router-dom";
import Button from "../Button/Button";

const images = [
  "assets/images/carousel-image-1.webp",
  "assets/images/carousel-image-2.webp",
  "assets/images/carousel-image-3.webp",
  "assets/images/carousel-image-4.webp",
];

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fadeClass, setFadeClass] = useState("fade-in");

  useEffect(() => {
    const interval = setInterval(() => {
      setFadeClass("fade-out");
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
        setFadeClass("fade-in");
      }, 1000); // Match this duration with the fade-out duration
    }, 5000); // Change slide every 3 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  return (
    <section className="hero">
      <div className="container">
        <div className="hero__left">
          <h1 className="hero__left-heading">
            Driving Success with Impactful{" "}
            <span className="highlighted-text">
              Branding
              <div className="animated-line-container">
                <svg
                  width="240"
                  height="13"
                  viewBox="0 0 240 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M217.684 0.0199036C217.641 0.425943 218.289 1.12864 212.592 1.4143C196.705 2.21074 146.112 3.14946 121.969 3.75246C82.287 4.74331 41.6801 5.21945 3.02582 7.82089C-4.079 8.29877 3.56152 11.5125 6.57009 12.5387C6.07538 11.5979 12.9642 11.3647 21.0051 10.8612C67.5242 7.94707 156.151 6.75135 208.013 5.64172C214.29 5.50742 218.212 5.28071 218.539 4.44417C218.913 3.48936 218.538 0.145084 217.684 0.0199036Z"
                    fill="#D8590E"
                  />
                </svg>
              </div>
            </span>{" "}
            Communication
          </h1>
          <p className="hero__left-text">
            Nyalu Communications is committed to delivering impactful and
            effectual marketing communication solutions that radically and
            positively affect the success of our clients’ business.
          </p>
          <div className="hero__left-buttons">
            <Link to="/contact-us">
              <Button>Contact Us</Button>
            </Link>
            <Link to="/about-us">
              <Button buttonStyle="primary-outline">About Us</Button>
            </Link>
          </div>
        </div>
        <div className="hero__right">
          <div className={`carousel ${fadeClass}`}>
            <img src={images[currentIndex]} alt="Carousel" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
