import React from "react";
import "./Footer.styles.scss";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__main">
          <div className="footer__main-item">
            <img src="assets/svg/logo.svg" alt="" className="footer-logo" />
            <div className="footer__main-item-list">
              <ul>
                <li>44 Siemert Road</li>
                <li>New Doornfontein</li>
                <li>Johannesburg</li>
                <li>2094</li>
              </ul>
            </div>
          </div>
          <div className="footer__main-item">
            <h3>Our Contacts</h3>
            <div className="footer__main-item-list">
              <ul>
                <li>Tel : (011) 402 8546</li>
                <li>Fax : (011) 402 8545</li>
                <li>Email : info@nyalu.co.za</li>
              </ul>
            </div>
          </div>
          <div className="footer__main-item">
            <h3>Menu</h3>
            <div className="footer__main-item-list">
              <ul>
                <NavLink to="/">
                  <li>Home</li>
                </NavLink>
                <NavLink to="/gallery">
                  <li>Gallery</li>
                </NavLink>
                <NavLink to="/about-us">
                  <li>About</li>
                </NavLink>
                <NavLink to="/contact-us">
                  <li>Contact</li>
                </NavLink>
              </ul>
            </div>
          </div>
          <div className="footer__main-item">
            <h3>Services</h3>
            <div className="footer__main-item-list">
              <ul>
                <NavLink to="/digital-printing">
                  <li>Digital Printing</li>
                </NavLink>
                <NavLink to="/litho-printing">
                  <li>Litho Printing</li>
                </NavLink>
                <NavLink to="/screen-printing">
                  <li>Screen Printing</li>
                </NavLink>
                <NavLink to="/embroidery">
                  <li>Embroidery</li>
                </NavLink>
              </ul>
            </div>
          </div>
          <div className="footer__iso">
            <img src="assets/images/iso-logo.webp" alt="" />
            <h4>ISO 9001:2015 certification</h4>
          </div>
        </div>
        <hr />
        <div className="footer__copyright">
          <div className="footer__copyright-left">
            <p>
              © 2024 Nyalu Communication. All rights reserved. Designed by Web
              Okes
            </p>
            {/* <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/cookie-policy">Cookie Policy</Link> */}
          </div>
          <div className="footer__copyright-right">
            <a
              href="https://www.linkedin.com/company/nyalu/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="assets/svg/LinkedIn.svg" alt="LinkedIn" />
            </a>
            <a
              href="https://www.youtube.com/channel/UCI0avbrnmeUTCiF5UysUvPA"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="assets/svg/Youtube.svg" alt="YouTube" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
