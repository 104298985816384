import React from "react";
import "./Loader.styles.scss";
const Loader = ({ small = true }) => {
  return (
    <div className="loader">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={small ? "25" : "50"}
        height={small ? "25" : "50"}
        viewBox="0 0 24 24"
        fill="none"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M21 12a9 9 0 11-6.219-8.56" />
      </svg>
    </div>
  );
};

export default Loader;
