import React from "react";
import "./WhyWeAreTheBest.styles.scss";
const WhyWeAreTheBest = () => {
  return (
    <section className="WhyWeAreTheBest">
      <div className="container">
        <h2 className="WhyWeAreTheBest__heading">Why We're the Best Choice</h2>
        <div className="WhyWeAreTheBest__list">
          <div className="WhyWeAreTheBest__list-item">
            <img src="assets/svg/fast-delivery.svg" />
            <h3
              data-aos="fade-up"
              data-aos-offset="50"
              data-aos-duration="1000"
            >
              Fast Delivery
            </h3>
            <p data-aos="fade-up" data-aos-offset="50" data-aos-duration="1500">
              To successfully manage all points for encounter between our
              clients and their stakeholder groups in order To attain set
              business objectives while building long lasting relationships with
              our clients.
            </p>
          </div>
          <div className="WhyWeAreTheBest__list-item">
            <img src="assets/svg/branding.svg" />
            <h3
              data-aos="fade-up"
              data-aos-offset="50"
              data-aos-duration="2000"
            >
              Combining Creativity & Branding
            </h3>
            <p data-aos="fade-up" data-aos-offset="50" data-aos-duration="2500">
              To deliver excellent marketing related services and products to
              our clients which exceed their expectations without fail.
            </p>
          </div>
          <div className="WhyWeAreTheBest__list-item">
            <img src="assets/svg/creativity.svg" />
            <h3
              data-aos="fade-up"
              data-aos-offset="50"
              data-aos-duration="3000"
            >
              Driving Creative Excellence
            </h3>
            <p data-aos="fade-up" data-aos-offset="50" data-aos-duration="3500">
              One of the key factors that sets us apart is our approach. We
              approach everything we do with an attitude of innovation and
              distinct creativity. 
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyWeAreTheBest;
