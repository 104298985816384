import { Toaster } from "react-hot-toast";
import Footer from "./components/Footer/Footer";
import MegaMenu from "./components/MegaMenu/MegaMenu";
import Navbar from "./components/Navbar/Navbar";
import ReviewsSlider from "./components/ReviewsSlider/ReviewsSlider";
import NavigationRoutes from "./NavigationRoutes/NavigationRoutes";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect, useState } from "react";
import { ScrollRestoration, useLocation } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import PageLoader from "./components/PageLoader/PageLoader";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      easing: "ease-in-out", // Easing function for the animation
      once: true, // Whether animation should happen only once while scrolling down
    });
  }, []);
  useEffect(() => {
    // Set an event listener for the window.onload event
    const handlePageLoad = () => {
      setLoading(false); // Hide the loader when the page has loaded
    };

    window.addEventListener("load", handlePageLoad);

    // Cleanup the event listener when the component is unmounted
    return () => window.removeEventListener("load", handlePageLoad);
  }, []);

  return (
    <>
      <PageLoader loading={loading} />
      <ScrollToTop />
      <div className="App">
        <Toaster position="top-left" />

        <Navbar />
        <NavigationRoutes />
        <Footer />
      </div>
    </>
  );
}

export default App;
