import React from "react";
import Gallery from "../../components/Gallery/Gallery";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const GalleryPage = () => {
  return (
    <div>
      <ScrollToTop />
      <Gallery />
    </div>
  );
};

export default GalleryPage;
