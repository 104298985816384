import React from "react";
import "./Header.styles.scss";

const Header = ({ children, backgroundImage }) => {
  return (
    <section
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className="header"
    >
      <h1>{children}</h1>
    </section>
  );
};

export default Header;
