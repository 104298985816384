import React, { useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./MobileMenu.styles.scss";
import { NavLink } from "react-router-dom";

const MobileMenu = ({ isMenuOpen, isServicesActive, setIsMenuOpen }) => {
  const [isServicesOpen, setIsServicesOpen] = useState(false);

  const toggleServices = () => setIsServicesOpen(!isServicesOpen);

  return (
    <div className={`mobile-menu ${isMenuOpen ? "active" : ""}`}>
      <img
        src="assets/svg/logo.svg"
        alt=""
        onClick={() => setIsMenuOpen(false)}
      />
      <CSSTransition
        in={isMenuOpen}
        timeout={500}
        classNames="menu"
        unmountOnExit
      >
        <ul className="menu">
          <NavLink
            to="/"
            onClick={() => {
              setIsMenuOpen(false);
              setIsServicesOpen(false);
            }}
          >
            <li>Home</li>
          </NavLink>
          <li>
            <button
              onClick={toggleServices}
              className={`dropdown-btn ${isServicesActive ? "active" : ""}`}
            >
              Services
            </button>
            <CSSTransition
              in={isServicesOpen}
              timeout={300}
              classNames="dropdown"
              unmountOnExit
            >
              <ul className="dropdown">
                <NavLink
                  to="/digital-printing"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <li>Digital Printing</li>
                </NavLink>
                <NavLink
                  to="/litho-printing"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <li>Litho Printing</li>
                </NavLink>
                <NavLink
                  to="/screen-printing"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <li>Screen Printing</li>
                </NavLink>
                <NavLink to="/embroidery" onClick={() => setIsMenuOpen(false)}>
                  <li>Embroidery</li>
                </NavLink>
              </ul>
            </CSSTransition>
          </li>
          <NavLink
            to="/gallery"
            onClick={() => {
              setIsMenuOpen(false);
              setIsServicesOpen(false);
            }}
          >
            <li>Gallery</li>
          </NavLink>
          <NavLink
            to="/about-us"
            oonClick={() => {
              setIsMenuOpen(false);
              setIsServicesOpen(false);
            }}
          >
            <li>About</li>
          </NavLink>
          <NavLink
            to="/contact-us"
            onClick={() => {
              setIsMenuOpen(false);
              setIsServicesOpen(false);
            }}
          >
            <li>Contact Us</li>
          </NavLink>
        </ul>
      </CSSTransition>
    </div>
  );
};

export default MobileMenu;
