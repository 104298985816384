import React from "react";
import "./Contact.styles.scss";
import ContactForm from "../ContactForm/ContactForm";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
const Contact = () => {
  return (
    <section className="Contact">
      <div className="container">
        <div className="Contact__left">
          <ContactForm />
        </div>
        <div className="Contact__right">
          <div className="Contact__right-details">
            <div className="Contact__right-details-item">
              <img src="assets/svg/phone.svg" alt="" />
              <h3>Phone</h3>
              <p>(011) 402 8546 </p>
            </div>
            <div className="Contact__right-details-item">
              <img src="assets/svg/fax.svg" alt="" />
              <h3>Fax</h3>
              <p> (011) 402 8545 </p>
            </div>
            <div className="Contact__right-details-item">
              <img src="assets/svg/Email.svg" alt="" />
              <h3>Email</h3>
              <p> info@nyalu.co.za </p>
            </div>
            <div className="Contact__right-details-item">
              <img src="assets/svg/pin.svg" alt="" />
              <h3>Office</h3>
              <p>44 Siemert Road , New Doornfontein, Johannesburg ,2094 </p>
            </div>
          </div>
          <div className="Contact__right-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14319.542100475766!2d28.0558634!3d-26.2004019!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e950e836df1eee7%3A0xb2cad5cc742b4518!2sNyalu%20Communications!5e0!3m2!1sen!2sza!4v1724334842335!5m2!1sen!2sza"
              height="450"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        <div className="Contact__bottom">
          <h3>
            Nyalu Communications has full-service printing production services
            for branding, brochures, sales support materials and annual reports.
          </h3>
        </div>
      </div>
    </section>
  );
};

export default Contact;
