import React from "react";
import Header from "../../components/Header/Header";
import LithoPrintingServices from "../../components/LithoPrintingServices/LithoPrintingServices";

const LithoPrintingPage = () => {
  return (
    <div className="LithoPrinting">
      <Header backgroundImage="assets/images/litho-printing-header.webp">
        Litho Printing
      </Header>
      <LithoPrintingServices />
    </div>
  );
};

export default LithoPrintingPage;
