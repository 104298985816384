import React from "react";
import "./Gallery.styles.scss";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
const Gallery = () => {
  return (
    <section className="gallery">
      <div className="container">
        <h1>Gallery</h1>
        <div className="gallery-items">
          <img src="assets/images/gallery/image.webp" alt="" />
          <img src="assets/images/gallery/image-1.webp" alt="" />
          <img src="assets/images/gallery/image-2.webp" alt="" />
          <img src="assets/images/gallery/image-3.webp" alt="" />
          <img src="assets/images/gallery/image-4.webp" alt="" />
          <img src="assets/images/gallery/image-5.webp" alt="" />
          <img src="assets/images/gallery/image-6.webp" alt="" />
          <img src="assets/images/gallery/image-7.webp" alt="" />
          <img src="assets/images/gallery/image-8.webp" alt="" />
          <img src="assets/images/gallery/image-9.webp" alt="" />
          <img src="assets/images/gallery/image-10.webp" alt="" />
          <img src="assets/images/gallery/image-11.webp" alt="" />
        </div>
      </div>
    </section>
  );
};

export default Gallery;
