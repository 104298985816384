import React from "react";
import "./Button.styles.scss";
import Loader from "../Loader/Loader";
const Button = ({
  children,
  buttonStyle = "primary",
  type,
  isLoading = false,
}) => {
  return (
    <button type={type} className={`button ${buttonStyle}`}>
      {children}
      {isLoading && <Loader />}
    </button>
  );
};

export default Button;
