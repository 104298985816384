import React from "react";
import Header from "../../components/Header/Header";
import DigitalPrintingServices from "../../components/DigitalPrintingServices/DigitalPrintingServices";

const DigitalPrintingPage = () => {
  return (
    <div>
      <Header backgroundImage="assets/images/digital-printing-header.webp">
        Digital Printing
      </Header>
      <DigitalPrintingServices />
    </div>
  );
};

export default DigitalPrintingPage;
