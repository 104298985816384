import React from "react";
import "./About.styles.scss";
import ServiceList from "../ServiceList/ServiceList";
const About = () => {
  return (
    <>
      <section className="About values">
        <div className="container">
          <div className="About__left">
            <div className="About__left-item">
              <img src="assets/svg/vision.svg" alt="" />
              <ServiceList
                heading="OUR VISION"
                headingSize="large"
                services={[
                  "To successfully manage all points of encounter between our clients and their stakeholders.",
                  "To attain set business objectives while building long lasting relationships with our clients.",
                  "To be the leading black-owned communications company.",
                ]}
                paragraphs={[]}
              />
            </div>
            <div className="About__left-item">
              <img src="assets/svg/dart-board-target.svg" alt="" />
              <ServiceList
                heading="OUR MISSION"
                headingSize="large"
                services={[]}
                paragraphs={[
                  "We thrive to deliver excellent marketing services and products to our clients and exceed expectations.",
                ]}
              />
            </div>
          </div>
          <div className="About__right">
            <img src="assets/images/transformation.webp" alt="" />
            <div className="About__right-card">
              <h2>TRANSFORMATION</h2>
              <p>
                Our company is committed to meeting the individual needs of our
                clients, incorporating values, cultures and services in line
                with the philosophies of economic transformation and inclusive
                growth. 
              </p>
              <p>
                Government for Black Economic Empowerment, and the amelioration
                of previously disadvantaged individuals and companies.
              </p>
              <p>
                As a 100% black-owned and managed company, we strive to assist
                in this process by providing a valuable service to the business
                community.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="About ceo">
        <div className="container">
          <div className="About__left">
            <img src="assets/images/ceo.webp" alt="" />
          </div>
          <div className="About__right">
            <div className="About__right-card">
              <h2>Meet Our CEO</h2>
              <p>
                Ephraim Mashisani - CEO holds Electronics Origination Diploma
                from Cross Media Training Centre formally known as South Africa
                Printing College.
              </p>
              <p>
                 He also holds a Teacher’s Diploma and a Management Certificate
                from GORDON Institute of Business Science (University of
                Pretoria). 
              </p>
              <p>
                Having worked closely with many Advertising companies, he brings
                a broader understanding of the media landscape in region.
              </p>
              <p>
                As a communications and event-management practitioner, he has
                extended his skills to a variety of government, parastatal and
                corporate clients.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default About;
