import React from "react";
import "./DigitalPrintingServices.styles.scss";
import ServiceList from "../ServiceList/ServiceList";

const DigitalPrintingServices = () => {
  return (
    <>
      <section className="DigitalPrintingServices">
        <div className="container">
          <div className="DigitalPrintingServices__left">
            <img src="assets/images/high-quality.webp" alt="" />
            <ServiceList
              heading="Small Format Digital Printing"
              paragraphs={[
                "We specialize in small-format digital printing, offering high-quality, customizable solutions for all your promotional needs. From business cards, brochures, and everything in between, our attention to detail ensures your materials make a lasting impression. Let us help you showcase your brand with creativity and style!",
              ]}
              services={[]}
            />
          </div>
          <div className="DigitalPrintingServices__right">
            <img src="assets/images/large-print.webp" alt="" />
            <ServiceList
              heading="Large Format Digital Printing"
              paragraphs={[
                "Our expertise includes vibrant billboards, eye-catching car wraps, durable banners, and custom gazebos that make a statement. ",
                "With high-quality materials and cutting-edge technology, we ensure your designs pop, attracting attention and driving engagement. Whether you’re promoting an event, enhancing your storefront, or launching a marketing campaign, we’ve got the perfect solution to help your business stand out.",
              ]}
              services={[]}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default DigitalPrintingServices;
