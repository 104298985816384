import React from "react";
import ServiceList from "../ServiceList/ServiceList";
import "./ScreenPrintingServices.styles.scss";

const ScreenPrintingServices = () => {
  return (
    <section className="PrintingServices">
      <div className="container">
        <div className="PrintingServices__item">
          <img
            src="assets/images/pad-printing.webp"
            alt=""
            className="PrintingServices__item-image"
          />
          <ServiceList
            heading="Pad Printing"
            paragraphs={[
              "A versatile printing method used to imprint designs onto irregularly shaped and diverse surfaces.",
              "This technique is ideal for items that require high precision and durability, making it perfect for promotional items and small components.",
            ]}
            services={[
              "Custom pens",
              "Phone cases",
              "Promotional gadgets",
              "Small plastic parts",
            ]}
          />
        </div>
        <div className="PrintingServices__item">
          <img
            src="assets/images/laser-engraving.webp"
            alt=""
            className="PrintingServices__item-image"
          />
          <ServiceList
            heading="Corporate gifts"
            paragraphs={[
              "Elevate your corporate gifting with us! We offer unique, customizable gifts that reflect your brand and strengthen relationships with clients and employees. From elegant merchandise to thoughtful wellness packages, our curated selection ensures every gift makes a lasting impression. Celebrate your connections with us!",
            ]}
            services={[]}
          />
        </div>
      </div>
    </section>
  );
};

export default ScreenPrintingServices;
