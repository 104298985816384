import React from "react";
import Header from "../../components/Header/Header";
import About from "../../components/About/About";

const AboutPage = () => {
  return (
    <div>
      <Header backgroundImage="assets/images/about-header.webp">
        About Us
      </Header>
      <About />
    </div>
  );
};

export default AboutPage;
