import React from "react";
import "./Services.styles.scss";
import { Link } from "react-router-dom";
const Services = () => {
  return (
    <section className="services">
      <div className="container">
        <h2>From Vision to Print</h2>
        <div className="services-items">
          <Link to="/screen-printing">
            <div
              className="services-items-item"
              data-aos="fade-right"
              data-aos-offset="50"
              data-aos-duration="3000"
            >
              <img src="assets/images/printing-service.webp" alt="" />
              <h3>Screen Printing</h3>
            </div>
          </Link>
          <Link to="/embroidery">
            <div
              className="services-items-item"
              data-aos="fade-right"
              data-aos-offset="50"
              data-aos-duration="2500"
            >
              <img src="assets/images/embroidery-service.webp" alt="" />
              <h3>Embroidery</h3>
            </div>
          </Link>
          <Link to="/digital-printing">
            <div
              className="services-items-item"
              data-aos="fade-right"
              data-aos-offset="50"
              data-aos-duration="2000"
            >
              <img src="assets/images/digital-printing.webp" alt="" />
              <h3>Digital Printing</h3>
            </div>
          </Link>
          <Link to="/litho-printing">
            <div
              className="services-items-item"
              data-aos="fade-right"
              data-aos-offset="50"
              data-aos-duration="1500"
            >
              <img src="assets/images/litho-service.webp" alt="" />
              <h3>Litho Printing</h3>
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Services;
