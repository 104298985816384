import React from "react";
import { Route, Routes } from "react-router-dom";
import HomePage from "../pages/Home/HomePage";

import EmbroideryPage from "../pages/Embroidery/EmbroideryPage";
import DigitalPrintingPage from "../pages/Digital Printing/DigitalPrintingPage";
import LithoPrintingPage from "../pages/Litho Printing/LithoPrintingPage";
import AboutPage from "../pages/About/AboutPage";
import ContactPage from "../pages/Contact/ContactPage";
import PageNotFound from "../pages/PageNotFound/PageNotFound";
import GalleryPage from "../pages/GalleryPage/GalleryPage";
import ScreenPrintingPage from "../pages/ScreenPrinting/ScreenPrintingPage";

const NavigationRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/screen-printing" element={<ScreenPrintingPage />} />
      <Route path="/embroidery" element={<EmbroideryPage />} />
      <Route path="/digital-printing" element={<DigitalPrintingPage />} />
      <Route path="/litho-printing" element={<LithoPrintingPage />} />
      <Route path="/gallery" element={<GalleryPage />} />
      <Route path="/about-us" element={<AboutPage />} />
      <Route path="/contact-us" element={<ContactPage />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default NavigationRoutes;
