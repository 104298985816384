import React from "react";
import "./Reviews.styles.scss";
import ReviewsSlider from "../ReviewsSlider/ReviewsSlider";

const Reviews = () => {
  return (
    <section className="reviews">
      <div className="container">
        <div className="reviews__left">
          <div className="reviews__left-heading">
            <p className="opinions">Real Opinions, Real Stories</p>
            <h2>
              <span
                className="first-g"
                data-aos="fade-left"
                data-aos-duration="4000"
                data-aos-delay="5000"
                primary
              >
                G
              </span>
              <span
                className="first-o"
                data-aos="fade-left"
                data-aos-duration="5000"
                primary
              >
                o
              </span>
              <span
                className="second-o"
                data-aos="fade-left"
                data-aos-duration="6000"
                primary
              >
                o
              </span>
              <span
                className="second-g"
                data-aos="fade-left"
                data-aos-duration="7000"
                primary
              >
                g
              </span>
              <span
                className="l"
                data-aos="fade-left"
                data-aos-duration="8000"
                primary
              >
                l
              </span>
              <span
                className="e"
                data-aos="fade-left"
                data-aos-duration="9000"
                primary
              >
                e
              </span>{" "}
              Reviews
            </h2>
            <div className="google-rating">
              <p>4.6</p>
              <img
                src="assets/svg/google-review.svg"
                alt=""
                className="stars"
              />
            </div>
          </div>
        </div>
        <div className="reviews__right">
          <ReviewsSlider />
        </div>
      </div>
    </section>
  );
};

export default Reviews;
