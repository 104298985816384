import React from "react";
import Header from "../../components/Header/Header";
import EmbroideryServices from "../../components/EmbroideryServices/EmbroideryServices";

const EmbroideryPage = () => {
  return (
    <div className="Embroidery">
      <Header backgroundImage="assets/images/embroidery-header.webp">
        Embroidery
      </Header>
      <EmbroideryServices />
    </div>
  );
};

export default EmbroideryPage;
