import React from "react";
import Button from "../../components/Button/Button";
import Hero from "../../components/Hero/Hero";
import Logos from "../../components/Logos/Logos";
import WhyWeAreTheBest from "../../components/WhyWeAreTheBest/WhyWeAreTheBest";
import Reviews from "../../components/Reviews/Reviews";
import ContactSection from "../../components/ContactSection/ContactSection";
import HomeGallery from "../../components/HomeGallery/HomeGallery";
import Services from "../../components/Services/Services";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const HomePage = () => {
  return (
    <div>
      <Hero />
      <Logos />
      <Services />
      <WhyWeAreTheBest />
      <HomeGallery />
      <Reviews />
      <ContactSection />
    </div>
  );
};

export default HomePage;
