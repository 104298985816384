import React from "react";
import "./LithoPrintingServices.styles.scss";
import ServiceList from "../ServiceList/ServiceList";
const LithoPrintingServices = () => {
  return (
    <>
      <section className="LithoPrintingServices litho">
        <div className="container">
          <div className="LithoPrintingServices__left">
            <img src="assets/images/offset-litho-printing.webp" alt="" />
            <ServiceList
              heading="Offset Litho Printing"
              headingSize="large"
              paragraphs={[
                " A traditional printing method known for its high-quality and consistent results, especially suited for large volume print runs.",
                "This service is ideal for producing detailed and color-accurate printed materials.",
              ]}
              services={["Magazines", "catalogs", "Books", "Large print runs"]}
            />
          </div>
          <div className="LithoPrintingServices__right"></div>
        </div>
      </section>
      <section className="LithoPrintingServices ">
        <div className="container">
          <div className="LithoPrintingServices__left">
            <ServiceList
              heading="Precision Finishing"
              headingSize="large"
              paragraphs={[
                "Completes the final touches on litho-printed materials, including binding and trimming.",
                "This service ensures that printed products have a polished, professional appearance, suitable for high-quality presentations.",
              ]}
              services={["Bound books", "Brochures", "Large-format signage"]}
            />
            <img src="assets/images/litho-printing-precision.webp" alt="" />
          </div>
          <div className="LithoPrintingServices__right"></div>
        </div>
      </section>
    </>
  );
};

export default LithoPrintingServices;
