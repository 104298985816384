import React from "react";
import "./ContactSection.styles.scss";
import ContactForm from "../ContactForm/ContactForm";
const ContactSection = () => {
  return (
    <section className="ContactSection">
      <div className="container">
        <div className="ContactSection__left">
          <img
            src="assets/images/reception.webp"
            alt=""
            className="ContactSection__left-image"
          />
        </div>
        <div className="ContactSection__right">
          <ContactForm />
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
