import React from "react";
import "./Logos.styles.scss";
import LogoScroller from "../LogoScroller/LogoScroller";
const Logos = () => {
  return (
    <div className="logos">
      <div className="container">
        <div className="logos__left">
          <h3 className="logos__left-heading">Our Esteemed Clients</h3>
        </div>
        <div className="logos__right">
          <LogoScroller />
        </div>
      </div>
    </div>
  );
};

export default Logos;
