import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import "./ContactForm.styles.scss";
import FormInput from "../FormInput/FormInput";
import FormTextarea from "../FormTextarea/FormTextarea";
import Button from "../Button/Button";
import Loader from "../Loader/Loader";

const ContactForm = () => {
  const [form, setForm] = useState({
    email: "",
    text: "",
    tel: "",
    user: "",
  });
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const validate = () => {
    if (!form.user) {
      toast.error("Name is required");
      return false;
    }

    if (!form.email) {
      toast.error("Email is required");
      return false;
    } else if (!/\S+@\S+\.\S+/.test(form.email)) {
      toast.error("Email is invalid");
      return false;
    }

    if (!form.text) {
      toast.error("Message is required");
      return false;
    }

    // Optional: Validate phone number format (if needed)
    if (form.tel && !/^\+?[\d\s]{10,15}$/.test(form.tel)) {
      toast.error("Phone number is invalid");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      return; // Stop submission if validation fails
    }

    setLoading(true);

    try {
      const token = await window.grecaptcha.execute(
        "6LeooD8qAAAAAIzaqrmWxZqfWAto7ovvXNxN2buJ",
        { action: "submit" }
      );
      setRecaptchaToken(token);

      const response = await axios.post(
        "http://localhost/email/send-email.php",
        {
          ...form,
          recaptchaToken: token,
        }
      );

      toast.success(response && response.data, {
        iconTheme: {
          primary: "#d8590e",
          secondary: "#FFFAEE",
        },
      });
      setForm({
        email: "",
        text: "",
        tel: "",
        user: "",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("An error occurred while sending the email");
    }
  };

  return (
    <div className="ContactForm">
      <h2>Send Us a Message</h2>
      <form className="ContactForm__form" onSubmit={handleSubmit}>
        <FormInput
          type="text"
          label="Name"
          name="user"
          onChange={handleChange}
          value={form.user}
          required
        />
        <FormInput
          type="email"
          label="Email"
          name="email"
          value={form.email}
          onChange={handleChange}
          required
        />
        <FormInput
          type="tel"
          label="Phone Number"
          name="tel"
          value={form.tel}
          onChange={handleChange}
        />
        <FormTextarea
          label="Message"
          name="text"
          value={form.text}
          onChange={handleChange}
          rows={5}
        />
        <Button type="submit" isLoading={loading}>
          SEND MESSAGE
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
