import React from "react";
import "./MegaMenu.styles.scss";
import { Link } from "react-router-dom";
const MegaMenu = ({ setIsMegaMenuVisible }) => {
  return (
    <div className="MegaMenu">
      <div className="container">
        <Link
          to="/digital-printing"
          onClick={() => setIsMegaMenuVisible(false)}
        >
          <div className="MegaMenu__item">
            <img src="assets/images/menu-digital.webp" alt="" />
            <h4>Digital Printing</h4>
            <p>Vibrant digital and large-format printing.</p>
          </div>
        </Link>
        <Link to="/litho-printing" onClick={() => setIsMegaMenuVisible(false)}>
          <div className="MegaMenu__item">
            <img src="assets/images/menu-litho.webp" alt="" />
            <h4>Litho Printing</h4>
            <p>Professional offset litho printing and finishing.</p>
          </div>
        </Link>
        <Link to="/screen-printing" onClick={() => setIsMegaMenuVisible(false)}>
          <div className="MegaMenu__item">
            <img src="assets/images/menu-printing.webp" alt="" />
            <h4>Screen Printing</h4>
            <p>High-quality print solutions for diverse materials.</p>
          </div>
        </Link>
        <Link to="/embroidery" onClick={() => setIsMegaMenuVisible(false)}>
          <div className="MegaMenu__item">
            <img src="assets/images/menu-embroidery.webp" alt="" />
            <h4>Embroidery</h4>
            <p>Custom and corporate embroidery services. </p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MegaMenu;
