import React from "react";
import "./HomeGallery.styles.scss";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
const HomeGallery = () => {
  return (
    <section className="HomeGallery">
      <h2>Our Work</h2>
      <img src="assets/images/home-gallery.webp" alt="" srcset="" />
      <Link to="/gallery">
        <Button>Browse Our Gallery</Button>
      </Link>
    </section>
  );
};

export default HomeGallery;
