import React from "react";
import "./FormInput.styles.scss";
const FormInput = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  name,
  required = false,
  disabled = false,
  readOnly = false,
}) => {
  return (
    <div className="FormInput">
      <div
        className="FormInput-group"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <label className="FormInput-label">{label}</label>{" "}
        <input
          type={type}
          className="FormInput-input"
          placeholder={placeholder && placeholder}
          value={value}
          onChange={onChange}
          name={name}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};

export default FormInput;
