import React from "react";
import "./FormTextarea.styles.scss";
const FormTextarea = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  name,
  required = false,
  disabled = false,
  readOnly = false,
  rows,
}) => {
  return (
    <div className="FormTextArea">
      <div className="FormTextArea-group">
        <label className="FormTextArea-label">{label}</label>{" "}
        <textarea
          type={type}
          className="FormTextArea-input"
          placeholder={placeholder && placeholder}
          value={value}
          onChange={onChange}
          name={name}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          rows={rows}
        />
      </div>
    </div>
  );
};

export default FormTextarea;
