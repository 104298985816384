import React from "react";
import "./PageLoader.styles.scss";

const PageLoader = ({ loading }) => {
  return (
    <section
      className={`PageLoader-container ${loading ? "" : "loader-hidden"}`}
    >
      <img src="assets/svg/logo.svg" alt="Logo" />
      <div className="PageLoader"></div>
    </section>
  );
};

export default PageLoader;
