import React from "react";
import Contact from "../../components/Contact/Contact";
import Header from "../../components/Header/Header";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const ContactPage = () => {
  return (
    <div>
      <Header backgroundImage="assets/images/contact-header.webp">
        Contact Us
      </Header>
      <Contact />
    </div>
  );
};

export default ContactPage;
