import React, { useState, useEffect } from "react";
import "./Navbar.styles.scss";
import { Link, NavLink, useLocation } from "react-router-dom";
import Button from "../Button/Button";
import MegaMenu from "../MegaMenu/MegaMenu";
import MobileMenu from "../MobileMenu/MobileMenu";

const Navbar = () => {
  const [isMegaMenuVisible, setIsMegaMenuVisible] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isServicesActive, setIsServicesActive] = useState(false);
  const { pathname } = useLocation();

  // Update isServicesActive based on the current path
  useEffect(() => {
    if (
      pathname === "/screen-printing" ||
      pathname === "/digital-printing" ||
      pathname === "/embroidery" ||
      pathname === "/litho-printing"
    ) {
      setIsServicesActive(true);
    } else {
      setIsServicesActive(false);
    }
  }, [pathname]);

  // Handle mouse enter/leave with slight delay for smooth transitions
  const handleMouseEnter = () => setIsMegaMenuVisible(true);

  const handleMouseLeave = () => {
    setTimeout(() => {
      setIsMegaMenuVisible(false);
    }, 200); // Delay for the fade-out effect
  };

  return (
    <header className="navbar">
      <div className="container">
        <div className="navbar__left">
          <div className="navbar__left-logo">
            <Link to="/">
              <img
                src="assets/svg/logo.svg"
                alt="Logo"
                className="navbar__left-logo"
              />
            </Link>
          </div>

          <nav className="navbar__left-links">
            <NavLink to="/">
              <li>Home</li>
            </NavLink>

            <li
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className={`services-link ${isServicesActive ? "active" : ""}`}
            >
              <span>
                Services
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.8977 15.6629C12.678 15.8826 12.3219 15.8826 12.1022 15.6629L6.36739 9.92804C6.14772 9.70837 6.14772 9.35227 6.36739 9.13259L6.63256 8.86739C6.85222 8.64772 7.20838 8.64772 7.42805 8.86739L12.5 13.9393L17.5719 8.86739C17.7916 8.64772 18.1477 8.64772 18.3674 8.86739L18.6326 9.13259C18.8522 9.35227 18.8522 9.70837 18.6326 9.92804L12.8977 15.6629Z"
                    fill="#6C6D6F"
                  />
                </svg>
              </span>
              {isMegaMenuVisible && (
                <MegaMenu
                  className="mega-menu fade-in"
                  setIsMegaMenuVisible={setIsMegaMenuVisible}
                />
              )}
            </li>

            <NavLink to="/gallery">
              <li>Gallery</li>
            </NavLink>
            <NavLink to="/about-us">
              <li>About</li>
            </NavLink>
            <NavLink to="/contact-us">
              <li>Contact</li>
            </NavLink>
          </nav>

          <div
            className={`navigation__hamburger ${isMenuOpen ? "active" : ""}`}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            aria-expanded={isMenuOpen}
          >
            <span className="navigation__hamburger-line1"></span>
            <span className="navigation__hamburger-line2"></span>
            <span className="navigation__hamburger-line3"></span>
          </div>
        </div>

        <div className="navbar__right">
          <Button buttonStyle="white">Get a Quote</Button>
        </div>
      </div>

      {/* Mobile menu rendered conditionally */}
      <MobileMenu
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        isServicesActive={isServicesActive}
      />
    </header>
  );
};

export default Navbar;
