import React from "react";
import "./ServiceList.styles.scss";
const ServiceList = ({
  services,
  heading,
  paragraphs = ["first", "secondary"],
  headingSize = "medium",
}) => {
  return (
    <div className="ServiceList">
      {headingSize === "medium" && <h2>{heading}</h2>}
      {headingSize === "large" && <h2>{heading}</h2>}
      <div>
        {paragraphs.map((i) => {
          return <p key={i}>{i}</p>;
        })}
      </div>
      <ul>
        {services.map((i) => {
          return (
            <li key={i}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 14.1667L9.16667 9.99999L5 5.83333M10.8333 14.1667L15 9.99999L10.8333 5.83333"
                  stroke="#F17327"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {i}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ServiceList;
