import React from "react";
import Header from "../../components/Header/Header";

import ScreenPrintingServices from "../../components/ScreenPrintingServices/ScreenPrintingServices";

const ScreenPrintingPage = () => {
  return (
    <div>
      <Header backgroundImage="assets/images/printing-header.webp">
        Printing
      </Header>
      <ScreenPrintingServices />
    </div>
  );
};

export default ScreenPrintingPage;
